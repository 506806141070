<template>
  <v-card flat>
    <div class="qrbox_design">
      <v-card-title>{{$t('message.mixed.scanQRCode')}}</v-card-title>
      <v-avatar @click="scanningVD()">
        <v-icon color="grey darken-3" size="33">mdi-qrcode-scan</v-icon>
      </v-avatar>
    </div>
    <v-card-title>
      <barcode-scanner v-model="scanCode" :search="scanCode" :scannerPayload="scannerPayload"
      :loading="respectiveViewLoading"></barcode-scanner>
    </v-card-title>
    <v-dialog v-model="scanDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('message.common.scan') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          {{ scanError }}
          <div>
            <video id="video" style="border: 1px solid gray; width:100%; height:auto;" ref="video"></video>
          </div>
          <div id="sourceSelectPanel" v-if="videoInputDevices.length > 1">
            <v-select id="sourceSelect" v-model="selectedDeviceId" outlined :items="videoInputDevices" hide-details
              item-text="label" item-value="deviceId" :label="$t('message.common.changeVideoSource')"></v-select>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="scanClose" @click="stopScanningSession">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text v-if="renderScrollList" class="pa-2 mt-2 mb-4">
      <v-card outlined color="#e8eef0" class="pa-0 mt-0"
        v-if="scrollListPayload.itemHeaders && scrollListPayload.list && scrollListPayload.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in scrollListPayload.itemHeaders">
            <v-col :cols="scrollListPayload.items[index].cols" :class="scrollListPayload.items[index].class" :key="index">
              <strong
                v-if="header === 'message.projects.drawingGroup' && scrollListPayload.advanceDrawingTypes">{{ $t(header) }}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{ $t(header) }}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll v-show="scrollListPayload.list.length > 0" :items="scrollListPayload.list"  :item-height="getItemHeight('renderScrollList')"
        max-height="95vh"  :title="scrollListPayload.list.length">
        <template v-slot:default="{ item }"
          v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card
            style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1">
            <v-card-text class="pa-1"
              :class="$t(scrollListPayload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in scrollListPayload.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"
                          :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block' : ''">
                          {{ item[colValues.value] }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{ colValues.label }}:
                          </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2"
                                  v-if="scrollListPayload.moduleTitle === 'message.layout.drawing'" dense hide-details
                                  @click.stop="" @change="saveStatusUpdate(scrollListPayload.list.indexOf(item))"
                                  v-model="item[subitems.value]" item-text="text" item-value="value"
                                  :items="subitems.items" label="Status" outlined></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!scrollListPayload.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                    v-if="!scrollListPayload.itemHeaders" />
                                  <span :class="subitems.spanClass">{{ item[subitems.value] === 1 ?
                                    $t('message.customModule.completed') : $t('message.customModule.notCompleted')
                                  }}</span>
                                </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="scrollListPayload.actionSize" :cols="scrollListPayload.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon
                      v-if="!scrollListPayload.isChecklist && scrollListPayload.hasDelete && checkActionPermission(scrollListPayload.module, CONSTANTS.DELETE)"
                      @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2  overflow-x-hidden" color="#e8eaf6">
            <v-card-text class="pa-0 px-4 my-2">
              <template v-for="(colValues, index) in scrollListPayload.items">
                <v-row :key="index" class="pt-1">
                  <v-col :cols="!scrollListPayload.hasDelete ? '4' : '3'" class="pa-0"
                    v-if="scrollListPayload.itemHeaders && scrollListPayload.itemHeaders.length">
                    <strong
                      v-if="scrollListPayload.itemHeaders[index] === 'message.projects.drawingGroup' && scrollListPayload.advanceDrawingTypes">{{$t(scrollListPayload.itemHeaders[index])}}</strong>
                    <strong
                      v-else-if="scrollListPayload.itemHeaders[index] !== 'message.projects.drawingGroup'">{{$t(scrollListPayload.itemHeaders[index])}}</strong>
                  </v-col>
                  <v-col :cols="!scrollListPayload.hasDelete ? '8' : '8'" class="pa-0"
                    :class="$t(scrollListPayload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value]}} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2"><strong
                          v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{
                        item[colValues.value] }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2"
                                v-if="scrollListPayload.moduleTitle === 'message.layout.drawing'" dense hide-details
                                @click.stop="" @change="saveStatusUpdate(scrollListPayload.list.indexOf(item))"
                                v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items"
                                label="Status" outlined></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass"
                                  v-if="!scrollListPayload.itemHeaders"><strong>{{ $t('message.projects.status')
                                    }}:</strong> </span><br v-if="!scrollListPayload.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ?
                                  $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon
                    v-if="!scrollListPayload.isChecklist && scrollListPayload.hasDelete && checkActionPermission(scrollListPayload.module, CONSTANTS.DELETE)"
                    @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template></v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded v-if="scrollListPayload.loading"
          height="6"></v-progress-linear>
        <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="false"></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!scrollListPayload.loading && (scrollListPayload.list.length === 0)" type="info"
        class="mt-2">
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="supplierScrollList" class="pa-2 mt-2 mb-4">
      <h4 class="headingdesign">{{ $t('message.layout.orders') }}</h4>
      <v-card outlined color="#e8eef0" class="pa-0 mt-0 "
        v-if="supplierorders.itemHeaders && supplierorders.list && supplierorders.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in supplierorders.itemHeaders">
            <v-col :cols="supplierorders.items[index].cols" :class="supplierorders.items[index].class" :key="index">
              <strong
                v-if="header === 'message.projects.drawingGroup' && supplierorders.advanceDrawingTypes">{{ $t(header) }}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{ $t(header) }}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll v-show="supplierorders.list.length > 0" :items="supplierorders.list" :item-height="getItemHeight('supplierScrollList')"
        max-height="95vh"  :title="supplierorders.list.length">
        <template v-slot:default="{ item }"
          v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card
            style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="orderdatalistRoute(item)">
            <!--:color="$t(scrollListPayload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? '#f19e05' : '#e8eaf6'" -->
            <v-card-text class="pa-1"
              :class="$t(supplierorders.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in supplierorders.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"
                          :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block' : ''">
                          {{ item[colValues.value] }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{ colValues.label }}:
                          </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2"
                                  v-if="scrollListPayload.moduleTitle === 'message.layout.drawing'" dense hide-details
                                  @click.stop="" @change="saveStatusUpdate(scrollListPayload.list.indexOf(item))"
                                  v-model="item[subitems.value]" item-text="text" item-value="value"
                                  :items="subitems.items" label="Status" outlined></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!scrollListPayload.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                    v-if="!scrollListPayload.itemHeaders" />
                                  <span :class="subitems.spanClass">{{ item[subitems.value] === 1 ?
                                    $t('message.customModule.completed') : $t('message.customModule.notCompleted')
                                  }}</span>
                                </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="supplierorders.actionSize" :cols="supplierorders.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon
                      v-if="!supplierorders.isChecklist && supplierorders.hasDelete && checkActionPermission(scrollListPayload.module, CONSTANTS.DELETE)"
                      @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2  overflow-x-hidden"
            :min-height="'150'" color="#e8eaf6"
            @click="checkActionPermission(supplierorders.module, CONSTANTS.EDIT) ? supplierorders.editHandler(item) : ''">
            <v-card-text class="pa-0 px-4 my-2">
              <template v-for="(colValues, index) in supplierorders.items">
                <v-row :key="index" class="pt-1">
                  <v-col :cols="!scrollListPayload.hasDelete ? '4' : '3'" class="pa-0"
                    v-if="scrollListPayload.itemHeaders && scrollListPayload.itemHeaders.length">
                    <strong
                      v-if="scrollListPayload.itemHeaders[index] === 'message.projects.drawingGroup' && scrollListPayload.advanceDrawingTypes">{{$t(scrollListPayload.itemHeaders[index])}}</strong>
                    <strong
                      v-else-if="scrollListPayload.itemHeaders[index] !== 'message.projects.drawingGroup'">{{$t(scrollListPayload.itemHeaders[index])}}</strong>
                  </v-col>
                  <v-col :cols="!scrollListPayload.hasDelete ? '8' : '8'" class="pa-0"
                    :class="$t(scrollListPayload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value]}} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2"><strong
                          v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{
                        item[colValues.value] }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2"
                                v-if="scrollListPayload.moduleTitle === 'message.layout.drawing'" dense hide-details
                                @click.stop="" @change="saveStatusUpdate(scrollListPayload.list.indexOf(item))"
                                v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items"
                                label="Status" outlined></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass"
                                  v-if="!scrollListPayload.itemHeaders"><strong>{{ $t('message.projects.status')
                                    }}:</strong> </span><br v-if="!scrollListPayload.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ?
                                  $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon
                    v-if="!supplierorders.isChecklist && supplierorders.hasDelete && checkActionPermission(supplierorders.module, CONSTANTS.DELETE)"
                    @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template></v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded v-if="supplierorders.loading"
          height="6"></v-progress-linear>
        <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="false"></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!supplierorders.loading && (supplierorders.list.length === 0)" type="info"
        class="mt-2">
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="eqidataScrollList" class="pa-2 mt-2 mb-4">
      <h4 class="headingdesign">{{ this.$t('message.layout.equipments') }}</h4>
      <v-card outlined color="#e8eef0" class="pa-0 mt-0 "
        v-if="eqidata.itemHeaders && eqidata.list && eqidata.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in eqidata.itemHeaders">
            <v-col :cols="eqidata.items[index].cols" :class="eqidata.items[index].class" :key="index">
              <strong
                v-if="header === 'message.projects.drawingGroup' && eqidata.advanceDrawingTypes">{{ $t(header) }}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{ $t(header) }}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll v-show="eqidata.list.length > 0" :items="eqidata.list" :item-height="getItemHeight('eqidataScrollList')"
         :title="eqidata.list.length">
        <template v-slot:default="{ item }"
          v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card
            style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="equipmentdatalistRoute(item)">
            <!--:color="$t(scrollListPayload.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? '#f19e05' : '#e8eaf6'" -->
            <v-card-text class="pa-1"
              :class="$t(eqidata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in eqidata.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"
                          :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block' : ''">
                          {{ item[colValues.value] }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{ colValues.label }}:
                          </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2" v-if="eqidata.moduleTitle === 'message.layout.drawing'" dense
                                  hide-details @click.stop="" @change="saveStatusUpdate(eqidata.list.indexOf(item))"
                                  v-model="item[subitems.value]" item-text="text" item-value="value"
                                  :items="subitems.items" label="Status" outlined></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!eqidata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br v-if="!eqidata.itemHeaders" />
                                  <span :class="subitems.spanClass">{{ item[subitems.value] === 1 ?
                                    $t('message.customModule.completed') : $t('message.customModule.notCompleted')
                                  }}</span>
                                </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="eqidata.actionSize" :cols="eqidata.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon
                      v-if="!eqidata.isChecklist && eqidata.hasDelete && checkActionPermission(eqidata.module, CONSTANTS.DELETE)"
                      @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2  overflow-x-hidden" color="#e8eaf6"
            @click="checkActionPermission(eqidata.module, CONSTANTS.EDIT) ? scrollListPayload.editHandler(item) : ''">
            <v-card-text class="pa-0 px-4 my-2">
              <template v-for="(colValues, index) in eqidata.items">
                <v-row :key="index" class="pt-1">
                  <v-col :cols="!eqidata.hasDelete ? '4' : '3'" class="pa-0"
                    v-if="eqidata.itemHeaders && eqidata.itemHeaders.length">
                    <strong
                      v-if="eqidata.itemHeaders[index] === 'message.projects.drawingGroup' && eqidata.advanceDrawingTypes">{{$t(eqidata.itemHeaders[index])}}</strong>
                    <strong
                      v-else-if="eqidata.itemHeaders[index] !== 'message.projects.drawingGroup'">{{$t(eqidata.itemHeaders[index])}}</strong>
                  </v-col>
                  <v-col :cols="!eqidata.hasDelete ? '8' : '8'" class="pa-0"
                    :class="$t(eqidata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value]}} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2"><strong
                          v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{
                        item[colValues.value] }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2" v-if="eqidata.moduleTitle === 'message.layout.drawing'" dense
                                hide-details @click.stop="" @change="saveStatusUpdate(eqidata.list.indexOf(item))"
                                v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items"
                                label="Status" outlined></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass" v-if="!eqidata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br v-if="!eqidata.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ?
                                  $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon
                    v-if="!eqidata.isChecklist && eqidata.hasDelete && checkActionPermission(eqidata.module, CONSTANTS.DELETE)"
                    @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template></v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded v-if="eqidata.loading"
          height="6"></v-progress-linear>
        <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="false"></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!eqidata.loading && (eqidata.list.length === 0)" type="info" class="mt-2">
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="chemicalScrollList" class="pa-2 mt-2 mb-4">
      <h4 class="headingdesign">{{ this.$t('message.layout.chemicallist') }}</h4>
      <v-card outlined color="#e8eef0" class="pa-0 mt-0 "
        v-if="chemicaldata.itemHeaders && chemicaldata.list && chemicaldata.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in chemicaldata.itemHeaders">
            <v-col :cols="chemicaldata.items[index].cols" :class="chemicaldata.items[index].class" :key="index">
              <strong
                v-if="header === 'message.projects.drawingGroup' && chemicaldata.advanceDrawingTypes">{{ $t(header) }}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{ $t(header) }}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll v-show="chemicaldata.list.length > 0" :items="chemicaldata.list" :item-height="getItemHeight('chemicalScrollList')"
         :title="chemicaldata.list.length">
        <template v-slot:default="{ item }"
          v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card
            style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="chemicaldatalistRoute(item)">
            <v-card-text class="pa-1"
              :class="$t(chemicaldata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in chemicaldata.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"
                          :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block' : ''">
                          {{ item[colValues.value] }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{ colValues.label }}:
                          </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2" v-if="chemicaldata.moduleTitle === 'message.layout.drawing'"
                                  dense hide-details @click.stop=""
                                  @change="saveStatusUpdate(chemicaldata.list.indexOf(item))"
                                  v-model="item[subitems.value]" item-text="text" item-value="value"
                                  :items="subitems.items" label="Status" outlined></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!chemicaldata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                    v-if="!chemicaldata.itemHeaders" />
                                  <span :class="subitems.spanClass">{{ item[subitems.value] === 1 ?
                                    $t('message.customModule.completed') : $t('message.customModule.notCompleted')
                                  }}</span>
                                </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="chemicaldata.actionSize" :cols="chemicaldata.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon
                      v-if="!chemicaldata.isChecklist && chemicaldata.hasDelete && checkActionPermission(chemicaldata.module, CONSTANTS.DELETE)"
                      @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2  overflow-x-hidden" color="#e8eaf6"
            @click="checkActionPermission(chemicaldata.module, CONSTANTS.EDIT) ? scrollListPayload.editHandler(item) : ''">
            <v-card-text class="pa-0 px-4 my-2">
              <template v-for="(colValues, index) in chemicaldata.items">
                <v-row :key="index" class="pt-1">
                  <v-col :cols="!chemicaldata.hasDelete ? '4' : '3'" class="pa-0"
                    v-if="chemicaldata.itemHeaders && chemicaldata.itemHeaders.length">
                    <strong
                      v-if="chemicaldata.itemHeaders[index] === 'message.projects.drawingGroup' && chemicaldata.advanceDrawingTypes">{{$t(chemicaldata.itemHeaders[index])}}</strong>
                    <strong
                      v-else-if="chemicaldata.itemHeaders[index] !== 'message.projects.drawingGroup'">{{$t(chemicaldata.itemHeaders[index])}}</strong>
                  </v-col>
                  <v-col :cols="!chemicaldata.hasDelete ? '8' : '8'" class="pa-0"
                    :class="$t(chemicaldata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value]}} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2"><strong
                          v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{
                        item[colValues.value] }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2" v-if="chemicaldata.moduleTitle === 'message.layout.drawing'"
                                dense hide-details @click.stop=""
                                @change="saveStatusUpdate(chemicaldata.list.indexOf(item))" v-model="item[subitems.value]"
                                item-text="text" item-value="value" :items="subitems.items" label="Status"
                                outlined></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass" v-if="!chemicaldata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                  v-if="!chemicaldata.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ?
                                  $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon
                    v-if="!chemicaldata.isChecklist && chemicaldata.hasDelete && checkActionPermission(chemicaldata.module, CONSTANTS.DELETE)"
                    @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template></v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded v-if="chemicaldata.loading"
          height="6"></v-progress-linear>
        <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="false"></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!chemicaldata.loading && (chemicaldata.list.length === 0)" type="info" class="mt-2">
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="projectdataScrollList" class="pa-2 mt-2 mb-4">
      <h4 class="headingdesign">{{
        this.hedingofproject === 'Event' ? this.$t('message.layout.event') :
        this.hedingofproject === 'PWHT' ? this.$t('message.layout.pwht') :
          this.hedingofproject === 'Drawing' ? this.$t('message.layout.drawing') :
            this.hedingofproject === 'Matrial List' ? this.$t('message.layout.matrialList') :
              this.hedingofproject === 'Weld Log' ? this.$t('message.layout.weldlog') :
                this.hedingofproject === 'NDT' ? this.$t('message.layout.ndt') :
                  this.hedingofproject === 'AS-Built' ? this.$t('message.layout.asbuilt') :
                    this.hedingofproject === 'Technical Report' ? this.$t('message.layout.technicalReport') :
                      this.hedingofproject === 'Surface Report' ? this.$t('message.layout.surfaceReport') :
                        this.hedingofproject === 'Pressure Test Report' ? this.$t('message.layout.pressureTestReport') :
                          this.hedingofproject === 'PWPS' ? this.$t('message.layout.pwps') :
                            this.hedingofproject
      }}
      </h4>
      <v-card outlined color="#e8eef0" class="pa-0 mt-0"
        v-if="projectdata.itemHeaders && projectdata.list && projectdata.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in projectdata.itemHeaders">
            <v-col :cols="projectdata.items[index].cols" :class="projectdata.items[index].class" :key="index">
              <strong
                v-if="header === 'message.projects.drawingGroup' && projectdata.advanceDrawingTypes">{{ $t(header) }}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{ $t(header) }}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll v-show="projectdata.list.length > 0" :items="projectdata.list" :item-height="getItemHeight('projectdataScrollList')"
         :title="projectdata.list.length">
        <template v-slot:default="{ item }"
          v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card
            style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="projectlistdataRoute(item)">
            <v-card-text class="pa-1"
              :class="$t(projectdata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in projectdata.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"
                          :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block' : ''">
                          {{ item[colValues.value] }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{ colValues.label }}:
                          </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2" v-if="projectdata.moduleTitle === 'message.layout.drawing'"
                                  dense hide-details @click.stop=""
                                  @change="saveStatusUpdate(projectdata.list.indexOf(item))"
                                  v-model="item[subitems.value]" item-text="text" item-value="value"
                                  :items="subitems.items" label="Status" outlined></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!projectdata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                    v-if="!projectdata.itemHeaders" />
                                  <span :class="subitems.spanClass">{{ item[subitems.value] === 1 ?
                                    $t('message.customModule.completed') : $t('message.customModule.notCompleted')
                                  }}</span>
                                </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="projectdata.actionSize" :cols="projectdata.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon
                      v-if="!projectdata.isChecklist && projectdata.hasDelete && checkActionPermission(projectdata.module, CONSTANTS.DELETE)"
                      @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2  overflow-x-hidden" color="#e8eaf6"
            @click="checkActionPermission(projectdata.module, CONSTANTS.EDIT) ? scrollListPayload.editHandler(item) : ''">
            <v-card-text class="pa-0 px-4 my-2">
              <template v-for="(colValues, index) in projectdata.items">
                <v-row :key="index" class="pt-1">
                  <v-col :cols="!projectdata.hasDelete ? '4' : '3'" class="pa-0"
                    v-if="projectdata.itemHeaders && projectdata.itemHeaders.length">
                    <strong
                      v-if="projectdata.itemHeaders[index] === 'message.projects.drawingGroup' && projectdata.advanceDrawingTypes">{{$t(projectdata.itemHeaders[index])}}</strong>
                    <strong
                      v-else-if="projectdata.itemHeaders[index] !== 'message.projects.drawingGroup'">{{$t(projectdata.itemHeaders[index])}}</strong>
                  </v-col>
                  <v-col :cols="!projectdata.hasDelete ? '8' : '8'" class="pa-0"
                    :class="$t(projectdata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value]}} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2"><strong
                          v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{
                        item[colValues.value] }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2" v-if="projectdata.moduleTitle === 'message.layout.drawing'"
                                dense hide-details @click.stop=""
                                @change="saveStatusUpdate(projectdata.list.indexOf(item))" v-model="item[subitems.value]"
                                item-text="text" item-value="value" :items="subitems.items" label="Status"
                                outlined></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass" v-if="!projectdata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                  v-if="!projectdata.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ?
                                  $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon
                    v-if="!projectdata.isChecklist && projectdata.hasDelete && checkActionPermission(projectdata.module, CONSTANTS.DELETE)"
                    @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template></v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded v-if="projectdata.loading"
          height="6"></v-progress-linear>
        <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="false"></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!projectdata.loading && (projectdata.list.length === 0)" type="info" class="mt-2">
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="ordersdataScrollList" class="pa-2 mt-2 mb-4">
      <h4 class="headingdesign">{{
        headingTitle.ordersdata === 3 ? this.$t('message.whsDashboard.supplierOrder') :
        headingTitle.ordersdata === 4 ? this.$t('message.whsDashboard.returnOrder') :
          headingTitle.ordersdata === 2 ? this.$t('message.whsDashboard.adjustmentOrders') :
            headingTitle.ordersdata === 1 ? this.$t('message.whsDashboard.transferOrders') :
              ''
      }}
      </h4>
      <v-card outlined color="#e8eef0" class="pa-0 mt-0 "
        v-if="ordersdata.itemHeaders && ordersdata.list && ordersdata.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in ordersdata.itemHeaders">
            <v-col :cols="ordersdata.items[index].cols" :class="ordersdata.items[index].class" :key="index">
              <strong
                v-if="header === 'message.projects.drawingGroup' && ordersdata.advanceDrawingTypes">{{ $t(header) }}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{ $t(header) }}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll v-show="ordersdata.list.length > 0" :items="ordersdata.list" :item-height="getItemHeight('ordersdataScrollList')"
         :title="ordersdata.list.length">
        <template v-slot:default="{ item }"
          v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card
            style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="ordersdatalistRoute(item)">
            <v-card-text class="pa-1"
              :class="$t(ordersdata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in ordersdata.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"
                          :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block' : ''">
                          {{ item[colValues.value] }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{ colValues.label }}:
                          </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2" v-if="ordersdata.moduleTitle === 'message.layout.drawing'"
                                  dense hide-details @click.stop=""
                                  @change="saveStatusUpdate(ordersdata.list.indexOf(item))" v-model="item[subitems.value]"
                                  item-text="text" item-value="value" :items="subitems.items" label="Status"
                                  outlined></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!ordersdata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                    v-if="!ordersdata.itemHeaders" />
                                  <span :class="subitems.spanClass">{{ item[subitems.value] === 1 ?
                                    $t('message.customModule.completed') : $t('message.customModule.notCompleted')
                                  }}</span>
                                </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="ordersdata.actionSize" :cols="ordersdata.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon
                      v-if="!ordersdata.isChecklist && ordersdata.hasDelete && checkActionPermission(ordersdata.module, CONSTANTS.DELETE)"
                      @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2  overflow-x-hidden" color="#e8eaf6"
            @click="checkActionPermission(ordersdata.module, CONSTANTS.EDIT) ? scrollListPayload.editHandler(item) : ''">
            <v-card-text class="pa-0 px-4 my-2">
              <template v-for="(colValues, index) in ordersdata.items">
                <v-row :key="index" class="pt-1">
                  <v-col :cols="!ordersdata.hasDelete ? '4' : '3'" class="pa-0"
                    v-if="ordersdata.itemHeaders && ordersdata.itemHeaders.length">
                    <strong
                      v-if="ordersdata.itemHeaders[index] === 'message.projects.drawingGroup' && ordersdata.advanceDrawingTypes">{{$t(ordersdata.itemHeaders[index])}}</strong>
                    <strong
                      v-else-if="ordersdata.itemHeaders[index] !== 'message.projects.drawingGroup'">{{$t(ordersdata.itemHeaders[index])}}</strong>
                  </v-col>
                  <v-col :cols="!ordersdata.hasDelete ? '8' : '8'" class="pa-0"
                    :class="$t(ordersdata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value]}} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2"><strong
                          v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{
                        item[colValues.value] }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2" v-if="ordersdata.moduleTitle === 'message.layout.drawing'" dense
                                hide-details @click.stop="" @change="saveStatusUpdate(ordersdata.list.indexOf(item))"
                                v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items"
                                label="Status" outlined></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass" v-if="!ordersdata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                  v-if="!ordersdata.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ?
                                  $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon
                    v-if="!ordersdata.isChecklist && ordersdata.hasDelete && checkActionPermission(ordersdata.module, CONSTANTS.DELETE)"
                    @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template></v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded v-if="ordersdata.loading"
          height="6"></v-progress-linear>
        <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="false"></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!ordersdata.loading && (ordersdata.list.length === 0)" type="info" class="mt-2">
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="productdataScrollList" class="pa-2 mt-2 mb-4">
      <h4 class="headingdesign">{{ this.$t('message.order.products') }}</h4>
      <v-card outlined color="#e8eef0" class="pa-0 mt-0"
        v-if="productdata.itemHeaders && productdata.list && productdata.list.length && $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
        <v-row class="ma-0">
          <template v-for="(header, index) in productdata.itemHeaders">
            <v-col :cols="productdata.items[index].cols" :class="productdata.items[index].class" :key="index">
              <strong
                v-if="header === 'message.projects.drawingGroup' && productdata.advanceDrawingTypes">{{ $t(header) }}</strong>
              <strong v-else-if="header !== 'message.projects.drawingGroup'">{{ $t(header) }}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-virtual-scroll v-show="productdata.list.length > 0" :items="productdata.list" :item-height="getItemHeight('productdataScrollList')"
         :title="productdata.list.length">
        <template v-slot:default="{ item }"
          v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'">
          <v-card
            style="overflow:hidden" outlined color="#e8eaf6" class="pa-1 mt-1" @click="productdatalistRoute(item)">
            <v-card-text class="pa-1"
              :class="$t(productdata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
              <v-row>
                <template>
                  <template v-for="(colValues, index) in productdata.items">
                    <template v-if="colValues.value">
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <div v-if="colValues.isParagraph" class="custom-height"
                          :class="item[colValues.value] && colValues.value == 'description' && item[colValues.value].length > 100 ? 'd-inline-block' : ''">
                          {{ item[colValues.value] }}
                        </div>
                        <span v-else :class="colValues.spanClass"><strong v-if="colValues.label">{{ colValues.label }}:
                          </strong> <br v-if="colValues.label" />{{ item[colValues.value] }}</span>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                        <v-row :class="colValues.rowClass">
                          <template v-for="(subitems, i) in colValues.subItems">
                            <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                              <template v-if="subitems.value === 'is_completed'">
                                <v-select class="px-0 pr-2" v-if="productdata.moduleTitle === 'message.layout.drawing'"
                                  dense hide-details @click.stop=""
                                  @change="saveStatusUpdate(productdata.list.indexOf(item))"
                                  v-model="item[subitems.value]" item-text="text" item-value="value"
                                  :items="subitems.items" label="Status" outlined></v-select>
                                <template v-else>
                                  <span :class="subitems.spanClass" v-if="!productdata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                    v-if="!productdata.itemHeaders" />
                                  <span :class="subitems.spanClass">{{ item[subitems.value] === 1 ?
                                    $t('message.customModule.completed') : $t('message.customModule.notCompleted')
                                  }}</span>
                                </template>
                              </template>
                              <template v-else-if="subitems.isTranslatable">
                                <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                              </template>
                              <span v-else :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                </template>
                <v-col v-if="productdata.actionSize" :cols="productdata.actionSize" class="pa-0 pr-1 mt-3">
                  <v-layout row wrap justify-end class="mx-0">
                    <v-btn max-height="24" max-width="24" icon
                      v-if="!productdata.isChecklist && productdata.hasDelete && checkActionPermission(productdata.module, CONSTANTS.DELETE)"
                      @click.stop="deleteRecord(item.id)" color="error">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else v-slot:default="{ item }">
          <v-card outlined class="pa-0 mt-2  overflow-x-hidden" color="#e8eaf6"
            @click="checkActionPermission(productdata.module, CONSTANTS.EDIT) ? scrollListPayload.editHandler(item) : ''">
            <v-card-text class="pa-0 px-4 my-2">
              <template v-for="(colValues, index) in productdata.items">
                <v-row :key="index" class="pt-1">
                  <v-col :cols="!productdata.hasDelete ? '4' : '3'" class="pa-0"
                    v-if="productdata.itemHeaders && productdata.itemHeaders.length">
                    <strong
                      v-if="productdata.itemHeaders[index] === 'message.projects.drawingGroup' && productdata.advanceDrawingTypes">{{$t(productdata.itemHeaders[index])}}</strong>
                    <strong
                      v-else-if="productdata.itemHeaders[index] !== 'message.projects.drawingGroup'">{{$t(productdata.itemHeaders[index])}}</strong>
                  </v-col>
                  <v-col :cols="!productdata.hasDelete ? '8' : '8'" class="pa-0"
                    :class="$t(productdata.moduleTitle) === $t('message.layout.drawing') && item && item.is_completed === 5 ? 'orange--text text--lighten-2' : ''">
                    <template v-if="colValues.value">
                      <div v-if="colValues.isParagraph" class="mx-2"> {{item[colValues.value]}} </div>
                      <span v-else :class="colValues.spanClass" class="mx-2"><strong
                          v-if="colValues.label">{{colValues.label}}: </strong> <br v-if="colValues.label" />{{
                        item[colValues.value] }}</span>
                    </template>
                    <template v-else>
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2" v-if="productdata.moduleTitle === 'message.layout.drawing'"
                                dense hide-details @click.stop=""
                                @change="saveStatusUpdate(productdata.list.indexOf(item))" v-model="item[subitems.value]"
                                item-text="text" item-value="value" :items="subitems.items" label="Status"
                                outlined></v-select>
                              <template v-else>
                                <span class="mx-2" :class="subitems.spanClass" v-if="!productdata.itemHeaders"><strong>{{
                                    $t('message.projects.status') }}:</strong> </span><br
                                  v-if="!productdata.itemHeaders" />
                                <span class="mx-2" :class="subitems.spanClass">{{item[subitems.value] === 1 ?
                                  $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                              </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span class="mx-2" :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else class="mx-2" :class="subitems.spanClass">{{$t(item[subitems.value])}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-layout row wrap justify-end class="mx-0 mr-5">
                  <v-btn icon
                    v-if="!productdata.isChecklist && productdata.hasDelete && checkActionPermission(productdata.module, CONSTANTS.DELETE)"
                    @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon large>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-row>
            </v-card-text>
          </v-card>
        </template></v-virtual-scroll>
      <p class="text-center px-5 py-0">
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded v-if="productdata.loading"
          height="6"></v-progress-linear>
        <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="false"></v-progress-circular>
      </p>
      <v-alert prominent text v-if="!productdata.loading && (productdata.list.length === 0)" type="info" class="mt-2" >
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="foundscrll">
        <div class="founddata">{{$t('message.common.no_data')}}</div>
    </v-card-text>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { BrowserMultiFormatReader } from '@zxing/library'
export default {
  data () {
    return {
      customerOrders: 0,
      supplierOrders: 0,
      adjustmentOrders: 0,
      transferOrders: 0,
      cycleCount: 0,
      scanCode: '',
      loading: true,
      hedingofproject: '',
      scanDialog: false,
      codeReader: {},
      videoInputDevices: [],
      selectedDeviceId: window.localStorage.getItem('WelditPwa_storeCamera'),
      scanError: '',
      searchTerm: '',
      headingTitle: {
        projectdata: '',
        ordersdata: '',
        chemicaldata: '',
        equipmentdata: '',
        orders: '',
        productdatas: ''
      },
      projectEquipmentObj: {
        isProjectEquipment: true,
        projectId: 1
      },
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.whsDashboard.scanText',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      scrollListPayload: {
        width: 65,
        loading: false,
        module: 'isrcanner',
        moduleTitle: 'Isrcanner',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for  delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.product.barcode',
          'message.product.supplierartno',
          'message.product.supplier',
          'message.equipments.product'
        ],
        items: [{
          value: 'barcode',
          class: 'pa-0 pa-1 text-justify',
          cols: '3',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'supplierartnumber',
          class: 'pa-0 pa-1 text-justify',
          cols: '3',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'suppliername',
          class: 'pa-0 pa-1 text-justify',
          cols: '3',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'productname',
          class: 'pa-0 pa-1 text-justify',
          cols: '3',
          spanClass: 'body pl-2',
          isParagraph: true
        }
        ],
        hasDelete: false,
        page: 1,
        previousSearch: '',
        recordsPerPage: 15
      },
      supplierorders: {
        width: 65,
        loading: false,
        module: 'isrcanner',
        moduleTitle: 'Isrcanner',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for  delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.common.name',
          'message.product.supplier',
          'message.common.date'
        ],
        items: [{
          value: 'number',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'suppliername',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'orderdate',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }
        ],
        hasDelete: false,
        page: 1,
        previousSearch: '',
        recordsPerPage: 15
      },
      eqidata: {
        width: 65,
        loading: false,
        module: 'isrcanner',
        moduleTitle: 'Isrcanner',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for  delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
        // 'message.chemicallist.chemicalName',
          'message.equipments.equipmentNumber',
          'message.common.name',
          'message.projects.status'
        ],
        items: [{
          value: 'equipment_number',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'name',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'status',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }
        ],
        hasDelete: false,
        page: 1,
        previousSearch: '',
        recordsPerPage: 15
      },
      chemicaldata: {
        width: 65,
        loading: false,
        module: 'isrcanner',
        moduleTitle: 'Isrcanner',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for  delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.common.name',
          'message.chemicallist.repository'
        ],
        items: [{
          value: 'name',
          class: 'pa-0 pa-1 text-justify',
          cols: '6',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'repository',
          class: 'pa-0 pa-1 text-justify',
          cols: '6',
          spanClass: 'body pl-2',
          isParagraph: true
        }
        ],
        hasDelete: false,
        page: 1,
        previousSearch: '',
        recordsPerPage: 15
      },
      projectdata: {
        width: 65,
        loading: false,
        module: 'isrcanner',
        moduleTitle: 'Isrcanner',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for  delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.projects.project',
          'message.common.date'
        ],
        items: [{
          value: 'project_name',
          class: 'pa-0 pa-1 text-justify',
          cols: '6',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'created_at',
          class: 'pa-0 pa-1 text-justify',
          cols: '6',
          spanClass: 'body pl-2',
          isParagraph: true
        }
        ],
        hasDelete: false,
        page: 1,
        previousSearch: '',
        recordsPerPage: 15
      },
      ordersdata: {
        width: 65,
        loading: false,
        module: 'isrcanner',
        moduleTitle: 'Isrcanner',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for  delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.common.orderno',
          'message.common.company',
          'message.order.orderDate'
        ],
        items: [{
          value: 'order_number',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'company',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'order_date',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }
        ],
        hasDelete: false,
        page: 1,
        previousSearch: '',
        recordsPerPage: 15
      },
      productdata: {
        width: 65,
        loading: false,
        module: 'isrcanner',
        moduleTitle: 'Isrcanner',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for  delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.product.barcode',
          'message.equipments.product',
          'message.product.supplier',
          'message.product.supplierartno'
        ],
        items: [{
          value: 'barcode',
          class: 'pa-0 pa-1 text-justify',
          cols: '2',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'products',
          class: 'pa-0 pa-1 text-justify',
          cols: '4',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'suppliers',
          class: 'pa-0 pa-1 text-justify',
          cols: '3',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: 'articlenumber',
          class: 'pa-0 pa-1 text-justify',
          cols: '3',
          spanClass: 'body pl-2',
          isParagraph: true
        }
        ],
        hasDelete: false,
        page: 1,
        previousSearch: '',
        recordsPerPage: 15
      },
      foundscrll: false,
      renderScrollList: false,
      ordersdataScrollList: false,
      productdataScrollList: false,
      projectdataScrollList: false,
      chemicalScrollList: false,
      eqidataScrollList: false,
      supplierScrollList: false,
      respectiveViewLoading: false
      // foundscrll: true
    }
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  mounted () {
    requestAnimationFrame(() => {
      if (this.$refs.scanFocus) {
        this.$refs.scanFocus.focus()
      }
    })
    this.$store.commit('scannedValue', '')
  },
  created () {
    // this.fieldsLoading = false
    this.$store.dispatch('getGeneralSettings')
    // if (/(http:\/\/|https:\/\/).+\..+\..+/.test(this.getHostRefApi)) { // check this.getHostRefApi variable is a correct URL
    //   this.updateFeaturesData()
    // }
  },
  methods: {
    getStatusText (status) {
      if (status === 1) return 'Active'
      else if (status === 2) return 'Quarantine'
      else if (status === 3) return 'Discarded'
      // Add more conditions for other status values if needed
      else return '' // Default value or handle other cases
    },
    async projectdatafun (data) {
      return await this.$api.execute('get', 'projects/get_as_simplified_for_pwa?filter=1')
        .then(response => {
          const newdtaid = response.data.find((newone) => newone.po_name === data.project_name)
          if (newdtaid) {
            const newProjectdata = [{
              project_name: data.project_name,
              created_at: moment(data.created_at).format('DD/MM/YYYY'),
              id: newdtaid.id,
              routetype: data.matchedFeature.displayname,
              tab: data.matchedFeature.tab,
              table: data.matchedFeature.table,
              moduleid: data.matchedFeature.id
            }]
            this.hedingofproject = data.matchedFeature.displayname
            this.projectdata.list = newProjectdata
            this.projectdataScrollList = true
          } else {
            this.foundscrll = true
          }
        })
        .finally(() => {
          // this.payload.loading = false
        })
    },
    getRespectiveProductOrder () {
      this.respectiveViewLoading = true
      if (this.scanCode.trim()) {
        const model = { search: `${this.scanCode}` }
        hostAppApi.post(`${this.getHostRefApi}irscanner`, model)
          .then((response) => {
            this.ordersdataScrollList = false
            this.productdataScrollList = false
            this.projectdataScrollList = false
            this.chemicalScrollList = false
            this.eqidataScrollList = false
            this.supplierScrollList = false
            this.renderScrollList = false
            this.respectiveViewLoading = false
            if (response.data.typedata === 'ordersproductdata') {
              this.scrollListPayload.list = response.data.prouductinfo
              this.supplierorders.list = response.data.supplierorders
              this.headingTitle.orders = 'Orders'
              this.renderScrollList = true
              this.supplierScrollList = true
            }
            if (response.data.typedata === 'product' || response.data.typedata === 'warehouseproductdata') {
              const productnewdata = [{
                ...response.data,
                barcode: response.data.barcode ?? '-',
                products: response.data.description ?? '-',
                suppliers: response.data.suppliers?.length > 0 ? response.data.suppliers[0]?.supplier_details?.name ?? '-' : 'No Suppliers',
                articlenumber: response.data.suppliers?.length > 0 ? response.data.suppliers[0]?.articlenumber ?? '-' : '-'
              }]
              this.productdata.list = productnewdata
              this.productdataScrollList = true
            }
            if (response.data.typedata === 'equipment') {
              const newdata = [{
                ...response.data,
                status: this.getStatusText(response.data.status)
              }]
              this.eqidata.list = newdata
              this.eqidataScrollList = true
            }
            if (response.data.typedata === 'chemical') {
              this.chemicaldata.list = [response.data]
              this.chemicalScrollList = true
            }
            if (response.data.typedata === 'orders') {
              const newOrdersdata = [{
                order_number: response.data.order_number,
                company: response.data.company ?? '-',
                order_date: moment(response.data.order_date).format('DD/MM/YYYY'),
                id: response.data.id
              }]
              this.headingTitle.ordersdata = response.data.order_type
              this.ordersdata.list = newOrdersdata
              this.ordersdataScrollList = true
            }
            if (response.data.typedata === 'projectdata') {
              this.projectdatafun(response.data)
            }
            if (response.data.typedata) {
              this.foundscrll = false
            } else {
              this.foundscrll = true
            }
          })
          .finally(() => { this.respectiveViewLoading = false })
      } else {
        this.respectiveViewLoading = false
      }
    },
    productdatalistRoute (item) {
      // this.$router.push(`/product/${item.id}`)
    },
    orderdatalistRoute (item) {
      this.$router.push(`/supplier_orders/${item.id}`)
    },
    equipmentdatalistRoute (item) {
      this.$router.push(`/equipments/${item.id}`)
    },
    chemicaldatalistRoute (item) {
      this.$router.push(`/chemicallist/${item.id}`)
    },
    ordersdatalistRoute (item) {
      if (this.headingTitle.ordersdata === 3) {
        this.$router.push(`/supplier_orders/${item.id}`)
      }
    },
    projectlistdataRoute (item) {
      if (item.table === 'project_customfolder_report') {
        this.$router.push(`/projects/${item.id}/custom_modules/${item.moduleid}?name=${item.tab}`)
      } else if (item.tab === 'deviation') {
        this.$router.push(`/projects/${item.id}/deviations`)
      } else {
        this.$router.push(`/projects/${item.id}/${item.tab}`)
      }
    },
    scanningVD (module) {
      const self = this
      this.scanDialog = true
      self.scanError = ''
      self.scanDialog = true
      // const selected = window.localStorage.getItem('WelditPwa_storeCamera')
      self.codeReader = new BrowserMultiFormatReader()
      self.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        self.videoInputDevices = videoInputDevices
        /* if (selected && selected !== 'undefined' && selected !== null) {
          self.selectedDeviceId = selected
        } else { */
        self.selectedDeviceId = this.$vuetify.breakpoint.mobile && videoInputDevices.length > 1 ? videoInputDevices[1].deviceId : videoInputDevices[0].deviceId
        // }
        this.scanFromVideoSource(self.selectedDeviceId, module)
      }).catch((err) => {
        console.log(err)
      })
    },
    scanFromVideoSource (videoDeviceId, module) {
      if (videoDeviceId) window.localStorage.setItem('WelditPwa_storeCamera', videoDeviceId)
      this.codeReader.decodeOnceFromVideoDevice(videoDeviceId, 'video')
        .then((result) => {
          this.searchTerm = result.text
          this.stopScanningSession()
          setTimeout(() => {
            this.getRespectiveValue()
          }, 50)
        }).catch((err) => {
          if (err && err.name && err.name === 'NotAllowedError') alert(this.$t('message.common.permissionDenied'))
        })
    },
    stopScanningSession () {
      this.scanDialog = false
      this.codeReader.reset()
      this.codeReader.stopContinuousDecode()
      this.codeReader = {}
    },
    getRespectiveValue () {
      const result = {}
      var qr = ''
      if (this.searchTerm.match(/o=.*m=/) || this.searchTerm.match(/oid=.mid=/)) {
        this.searchTerm.split('?')[1].split('&').map(x => {
          result[x.split('=')[0]] = x.split('=')[1]
        })
      }
      if (!this.searchTerm) {
        return
      }
      let barCode = null
      const firstSplit = this.searchTerm.toString().split('?')
      if (firstSplit.length) {
        const secondSplit = firstSplit[0].split('/')
        if (secondSplit.length > 1) {
          barCode = secondSplit[1]
        }
      }
      if (!barCode) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.mixed.barCodeNotDecoded' })
        return
      }

      const formData = new FormData()
      const data = Object.keys(result)
      data.forEach(key => {
        formData.append(key, result[key])
      })
      hostAppApi.post(`${this.getHostRefApi}qrcode/${barCode}`, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            try {
              if (result !== {} && result.is_pr && result.tid) {
                if (result.mid === 'deviation') result.mid = 'deviations'
                else if (result.mid === 'project-checklist') result.mid = 'checklists'
                this.$router.push(`/projects/${result.tid}/${result.mid}`)
              } else if (result.mid === 'equipment') {
                this.$router.push(`/equipments/${result.oid}`)
              } else if (result.mid === 'product') {
                qr = this.searchTerm.split('?')[0].split('/')
                this.$router.push(`/product_view?qr=${qr[qr.length - 1].replace('&', '-')}`)
              } else if (result.i && result.t) {
                if (result.m === 'deviation') result.m = 'deviations'
                else if (result.m === 'project-checklist') result.m = 'checklists'
                if (result.c) this.$router.push(`/projects/${result.t}/custom_modules/${result.c}?name=${result.m}`)
                else this.$router.push(`/projects/${result.t}/${result.m}`)
              } else if (result.m === 'equipment') {
                this.$router.push(`/equipments/${result.o}`)
              } else if (result.m === 'chemicallist') {
                this.$router.push(`/chemicallist/${result.o}`)
              } else if (result.m === 'product' || result.m === 'warehouseorder') {
                this.selectedProductCode = this.searchTerm.split('?')[0].slice(1)
                this.showProjectSelectDialog = true
                this.getProjectsByUsedProduct()
              } else if (result.m) {
                qr = this.searchTerm.split('?')[0].split('/')
                this.$router.push(`/custom_modules?qr=${qr[qr.length - 1].replace('&', '-')}`)
              } else {
                this.$router.push('/not_found')
              }
            } catch (e) {
              this.$router.push('/not_found')
            }
          }
        })
        .catch((e) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.noResults' })
          this.$router.push('/not_found')
        })
    },
    getItemHeight (item) {
      const { xs, sm, md, lg, xl } = this.$vuetify.breakpoint

      const heightMap = {
        renderScrollList: { xs: 230, sm: 90 },
        ordersdataScrollList: { xs: 140, sm: 75 },
        productdataScrollList: { xs: 200, sm: 75 },
        projectdataScrollList: { xs: 110, sm: 75 },
        chemicalScrollList: { xs: 110, sm: 75 },
        eqidataScrollList: { xs: 170, sm: 75 },
        supplierScrollList: { xs: 160 },
        default: { xs: 65, sm: 65, md: 65, lg: 65, xl: 65 }
      }
      const itemHeight = heightMap[item] || heightMap.default

      return (xs && itemHeight.xs) || (sm && itemHeight.sm) || (md && itemHeight.md) || (lg && itemHeight.lg) || (xl && itemHeight.xl) || 65
    }
  },
  watch: {
    reloadApp (val) {
      if (val) this.$router.go()
    },
    selectedDeviceId (val) {
      if (val) {
        window.localStorage.setItem('WelditPwa_storeCamera', val)
        this.scanFromVideoSource(val, this.module)
      }
    },
    '$i18n.locale' (val) {
      if (val) this.updateFeaturesData()
    }
  }
}
</script>
<style>
.headingdesign {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  color: #000;
  margin-bottom: 25px;
  text-transform: capitalize;
}
.founddata {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  color: #000;
  margin-bottom: 25px;
  text-transform: capitalize;
  margin-top: 35px;
}
.qrbox_design{
  display: flex;
  align-items: center;
  justify-content: right;
  padding-top: 10px;
  padding-right: 15px;
}
.qrbox_design .v-card__title{
  padding: 0 !important;
  font-size: 18px;
}
.qrbox_design i {
  cursor: pointer;
}
</style>
